<template>
    <div>
        <svg width="26" height="26" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_323_334)">
                <path d="M5.01005 3L4.30505 3.705L6.59505 6L4.30505 8.295L5.01005 9L8.01005 6L5.01005 3Z" fill="white" class="alterColor" />
            </g>
            <defs>
                <clipPath id="clip0_323_334">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>


    </div>
</template>
  
<script>

export default {
    name: "notiSwitch",
};
</script>
<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.alterColor {
    fill: $clr-saina-light-dark-main-bg
}
</style>