<template>
    <div>
        <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_323_331)">
                <path
                    d="M8 4.5V9.5H4V4.5H8ZM7.25 1.5H4.75L4.25 2H2.5V3H9.5V2H7.75L7.25 1.5ZM9 3.5H3V9.5C3 10.05 3.45 10.5 4 10.5H8C8.55 10.5 9 10.05 9 9.5V3.5Z"
                    fill="white" class="alterColor" />
            </g>
            <defs>
                <clipPath id="clip0_323_331">
                    <rect width="12" height="12" fill="white" />
                </clipPath>
            </defs>
        </svg>

    </div>
</template>
  
<script>

export default {
    name: "notiSwitch",
};
</script>
<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.alterColor{
fill:$clr-saina-light-dark-main-bg
}

</style>