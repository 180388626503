
<template>
    <svg width="13px" height="8px" viewBox="0 0 13 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: sketchtool 61.2 (101010) - https://sketch.com -->
    <title>6B40D432-83D9-46AF-94F3-2E9A15A33E1C</title>
    <desc>Created with sketchtool.</desc>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="NTX-IMCL-Assets" transform="translate(-77.000000, -170.000000)">
            <g id="down_arrow" transform="translate(71.500000, 162.000000)">
                <polygon id="Path" fill="#DFC46B "  class="alterColor" fill-rule="nonzero" transform="translate(12.295000, 12.000000) rotate(90.000000) translate(-12.295000, -12.000000) " points="8.59 16.59 13.17 12 8.59 7.41 10 6 16 12 10 18"></polygon>
                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
            </g>
        </g>
    </g>
</svg>
</template>

<script>

export default {
    name: "arrowdown",
};
</script>
<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.alterColor{
fill:$clr-saina-light-dark-main-bg
}

</style>